import { login,logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getInfo } from '@/api/admin'
import { deepClone } from '@/utils/util'
import { TimeZone }  from '@/utils/timeZone'
import Cookies from 'js-cookie'
import { getStore, setStore } from '@/utils/store'
import store from '..'
import { getconfigbykey } from '@/api/district'
import project from '@/utils/project'

var webiste = {
    menu: {
        props: {
            label: 'label',
            path: 'path',
            icon: 'icon',
            children: 'children'
        }
    }
}
const user = {
    state: {
        sellerId:"",
        permissions: {},
        token: getToken(),
        sellerName: '',
        avatar: '',
        roles: null,
        info: null,
        userinfotoken: null,
        accountId: 0,
        // menu: getStore({
        //     name: 'menu'
        // }) || [],
        menu:[],
        menuAll: [],
        countryInfo: {},
        countryTimeZone: 0,
        countryCurrencyPrecision: 0,
        countryCurrencySymbol: "",
        timeZone:{},
        pageId: ''
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.sellerName = name
        },
        SET_PAGE_ID: (state, pageId) => {
            state.pageId = pageId || ''
        },
        SET_ID: (state, id) => {
            state.sellerId = id
        },
        SET_INFO: (state, info) => {
            state.info = info
            setStore({
                name: 'userInfo',
                content: info
            })
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_USERINFOTOKEN: (state, userinfotoken) => {
            state.userinfotoken = userinfotoken
        },
        SET_ACCOUNTID: (state, accountId) => {
            state.accountId = accountId
        },
        SET_PERMISSIONS: (state, permissions) => {
            const list = {}
            for (let i = 0; i < permissions.length; i++) {
                list[permissions[i]] = true
            }
            state.permissions = list
            // setStore({
            //     name: 'permissions',
            //     content: permissions
            // })
        },
        SET_MENU: (state, menu) => {
            state.menu = menu
            // setStore({
            //     name: 'menu',
            //     content: menu,
            // })
        },
        SET_MENU_ALL: (state, menuAll) => {
            state.menuAll = menuAll
        },
        SET_USERIFNO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_COUNTRY_INFO: (state, countryInfo) => {
            state.countryInfo = countryInfo
            state.countryCurrencySymbol = countryInfo.currencySymbol
            state.currencyScale = countryInfo.currencyScale
            state.countryTimeZone = countryInfo.timeZone,
            state.timeZone = new TimeZone(countryInfo.countryCode, "", countryInfo.timeZone);
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            // userInfo.username = userInfo.username.trim()
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    const data = response
                    console.log(data.access_token)
                    console.log('Bearer ' + data.access_token)
                    console.log('Bearer')
                    let token = 'Bearer ' + data.access_token
                    console.log(token)
                    setToken(token)

                    commit('SET_TOKEN', token) // commit：同步操作，写法：this.$store.commit('mutations方法名',值)
                    commit('SET_USERINFOTOKEN', data) // commit：同步操作，写法：this.$store.commit('mutations方法名',值)
                    commit('SET_ACCOUNTID', data.accountId) // commit：同步操作，写法：this.$store.commit('mutations方法名',值)
                    console.log('Login，SET_TOKEN')

                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },


        // 获取用户信息
        GetInfo({ commit, state, dispatch }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token).then(response => {
                    const data = response.data
                    if (!(data && data.id)) { // 验证返回的roles是否是一个非空数组
                        reject({
                            code: 9999999,
                            data: null,
                            msg: "getInfo: roles must be a non-null array !",
                            notSuccess: true,
                            success: false
                        })
                    }
                    commit('SET_NAME', data.countryName)
                    // commit('SET_AVATAR', data.avatar)
                    commit('SET_ID', data.id)
                    commit('SET_INFO', data)
                    commit('SET_COUNTRY_INFO', data.countryInfo)
                    // dispatch('getPageId')
                    !store.getters.timInfo.sdkAppId && store.dispatch('initTim')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                // removeToken()
                // commit('SET_TOKEN', '');
                // commit('SET_ROLES', []);
                // commit('SET_PERMISSIONS', []);
                // console.log("LogOut")
                // resolve()
                logout(state.token).then(() => {
                    commit('SET_MENU', [])
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', null)
                    commit('SET_USERINFOTOKEN', null)
                    commit('SET_PERMISSIONS', [])
                    console.log('LogOut')
                    removeToken()

                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({ commit, dispatch }) {
            return new Promise(resolve => {
                // dispatch('LogOut')
                commit('SET_MENU', [])
                // commit('SET_TAGSVIEW_MULTLANGUAGE', {})
                commit('SET_TOKEN', '')
                console.log('FedLogOut')
                commit('SET_ROLES', null)
                commit('SET_USERINFOTOKEN', null)
                commit('SET_PERMISSIONS', [])
                // dispatch('delAllViews')
                removeToken()

                resolve()
            })
        },

        async getPageId({ commit, state }) {
            try {
                const res = await getconfigbykey({ key: `inner_seller_go_home_show_pop_${state.countryInfo.countryCode}` })
                commit('SET_PAGE_ID', res.data || '')
            } catch (error) {
                console.log(error);
            }
        }
    }
}




export default user


export const isUndef = x => typeof x === 'undefined'
export const isNumber = x => typeof x === 'number'
export const isFunc = x => typeof x === 'function'
export const isNull = x => x === null
export const isString = x => typeof x === 'string'

export const isPromise = x => {
    return (
        isUndef(x) &&
        isFunc(x.then) &&
        isFunc(x.catch)
    )
}

export const isJson = function (str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            console.log('error：' + str + '!!!' + e);
            return false;
        }
    }
    console.warn('It is not a string!')
    return false
}
import numeral from 'numeral'
import accounting from 'accounting'

/**
 * 统一配置货币默认设置
 * http://openexchangerates.github.io/accounting.js/#documentation
 * by linq at 2021/8/14
 */
let setCurrencyDefaultSetting = (options) => {
    if (!options){
        return;
    }
    options =  Object.assign({}, accounting.settings.currency, options);
    accounting.settings.currency = options;
}
accounting.settings.currency.symbol = '';
accounting.settings.currency.format = "%s %v";

/**
 * 统一配置数字默认设置
 * http://openexchangerates.github.io/accounting.js/#documentation
 * by linq at 2021/8/14
 */
let setNumberDefaultSetting = (options) => {
    if (!options){
        return;
    }
    options =  Object.assign({}, accounting.settings.number, options);
    accounting.settings.number  = options;
}

/**
 * 覆盖掉accounting.toFixed 函数，未来好统一管理精度
 * http://openexchangerates.github.io/accounting.js/#documentation
 * @param {*} num 
 * @param {*} [precision =2] 精度可选填
 * @returns string
 */
let toFixed = (num, precision) => {
    precision = typeof precision === 'number' ? precision : accounting.settings.number.precision;
    return accounting.toFixed(num, precision);
}

/**
 * 数字比较，规避浮点数运算精度问题，导致数字不相等
 * @param {*} number1 
 * @param {*} number2 
 * @param {*} precision 比较精度
 * @returns 
 */
let isEqual = (number1, number2, precision = 10) => {
    return toFixed(number1, precision) == toFixed(number2, precision);
}

/**
 * http://numeraljs.com/#format
 * @param {*} num 
 * @returns 
 */
let formatTime = (num) => {
    return numeral(num).format('00:00:00');
}

/**
 * 统一格式化为%
 */
let percentagesUtil = (function(){
    let defaultPercentagesSettings = {
        format: '0.00%'
    }

    /**
     * 设置的百分号默认设置
     * @param {*} options {percision, format}
     */
    this.setPercentagesSetting = (options) => {
        if (!options){
            return;
        }
        defaultPercentagesSettings =  Object.assign({}, defaultPercentagesSettings, options);
    }

    /**
     * 
     * @param {*} num 
     * @param {*} format 
     * http://numeraljs.com/#format
     * @returns 
     */
    this.formatPercentages = (num, format = '0.00%') => {
        format = format || defaultPercentagesSettings.format;
        return numeral(num).format(format);
    }

    return this;
}).call({});

/**
 * 统一设置数字、货币、百分比的默认配置
 * @param {*} {number, currency, percentages} 
 */
let setDefaultSetting = ({number, currency, percentages}) => {
    number && setNumberDefaultSetting(number);
    currency && setCurrencyDefaultSetting(currency);
    percentages && percentagesUtil.setPercentagesSetting(percentages);
}
const convertFirstArgsToNumber = (args) => {
    if (args.length > 0){
        args[0] = Number(args[0]) || 0;
    }
    return args;
}
let formatMoney = (...args) =>{
    let len = args.length;
    args = convertFirstArgsToNumber(args);
    if (len >1){
        if (typeof args[len-1] === 'object'){
            args[len - 1].symbol = '';
        } else {
            args[1] ='';
        }
    } else {
        args.push('');
    }
    return accounting.formatMoney.apply(accounting, args);
}
let formatCurrencyMoney = (...args) => {
    args = convertFirstArgsToNumber(args);
    return accounting.formatMoney.apply(accounting, args); 
}

export default {
    numeral,
    formatTime,
    isEqual,
    ...accounting,
    formatMoney,
    formatCurrencyMoney,
    ...percentagesUtil,
    setCurrencyDefaultSetting,
    setNumberDefaultSetting,
    setDefaultSetting,
    toFixed
}


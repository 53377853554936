/**
 * @desc 曝光指令，检测元素是否进入视口
 * 
 * @example
 * 每次都触发
 * <div v-observe-exposure="handleExposure"></div>
 * 只触发一次
 * <div v-observe-exposure.once="handleExposure"></div>
 */
const observeExposure = {
    inserted: function (el, binding, vnode) {
        const callback = binding.value
        const once = binding.modifiers.once
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callback()

                    if(once) {
                        // 停止观察
                        observer.unobserve(el)
                    }
                }
            })
        })
        observer.observe(el)
    }
}

export default observeExposure


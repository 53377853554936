export default {
    single: "单",
    home: "家",
    all: "全部",
    true: "是",
    false: "否",
    family: "家",
    list: "单",
    gram: "克",
    Zhang: "张",
    reduce: "减",
    plus: "加",
    stage: "期",
    theFirst: "第",
    yuan: "元",
    full: "满",
    hit: "打",
    day: "天",
    fracture: "折",
    hour: "小时",
    minute: "分钟",
    second: "秒",
    piece: "件"
}
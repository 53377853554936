import Cookies from 'js-cookie'
import { getStore, setStore,removeStore } from '@/utils/store'
import { isSimbaAppEnv } from '@/utils/common'

const TokenKey = 'Admin-Token'
const BASIC_TOKEN = "Basic enV1bDp6dXVs";
// getStore({name:"userInfo"})
export function getToken () {
    // console.log('getToken')
    if (isSimbaAppEnv()) {
        const appToken = Cookies.get('token')
        if (appToken) {
            const _token = `Bearer ${appToken}`
            setToken(_token)
            return _token
        }
    }
    if (Cookies.get(TokenKey)) {
        return Cookies.get(TokenKey)
    } else if (getStore({ name: TokenKey })) {
        return getStore({ name: TokenKey })
    } else {
        return BASIC_TOKEN
    }

}

const COUNTRY_ID_KEY = 'countryId'
/**
 * 获取登录成功之后的CountryID，优先取SessionStorage, 其次去localStorage
 * @returns 返回登录成功之后的countryId
 */
export function getCountryByStore(){
    const userInfo = getStore({name:"userInfo"})
    if(userInfo && userInfo.countryId){
        return userInfo.countryId
    }
    return ''
}


/**
 * 是否存在不为空的Token值
 * @returns Boolean
 */
export function isExistLoginBasicToken(){
    let token = getToken()
    return token && token != BASIC_TOKEN;
}

export function setToken (token) {
    // console.log('setToken')
    setStore({
        name: TokenKey,
        content: token,
        type:'localStorage'
    })
    return Cookies.set(TokenKey, token)
}

export function removeToken () {
    // console.log('removeToken')
    removeStore({name:TokenKey})
    return Cookies.remove(TokenKey)
    // return Cookies.set(TokenKey,'Basic enV1bDp6dXVs')
}

import { tim } from './index'
import TIM from 'tim-js-sdk'
import store from '../store';

let isReady = false
// SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能
export let onSdkReady = function (event) {
    console.log('onSdkReady', event);
    isReady = true
    store.commit('SET_TIM_STATUS', true)
    // let message = tim.createTextMessage({ to: 'EGATEE-B-222182', conversationType: TIM.TYPES.CONV_C2C, payload: { text: 'Hello world!' } });
    // tim.sendMessage(message);
};

// SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态
export let onSdkNotReady = async function(event) {
    try {
        console.log('onSdkNotReady', event);
        isReady = false
        // tim.login({ userID: res.data.userID, userSig: res.data.userSig });
    } catch (error) {
        console.log(error);
    }
    // 如果想使用发送消息等功能，接入侧需驱动 SDK 进入 ready 状态，重新调用 login 接口即可，如下所示：
    // tim.login({userID: 'your userID', userSig: 'your userSig'});
};

// SDK 收到推送的单聊、群聊、群提示、群系统通知的新消息，接入侧可通过遍历 event.data 获取消息列表数据并渲染到页面
export let onMessageReceived = function (event) {
    // event.data - 存储 Message 对象的数组 - [Message]
    console.log('新消息列表更新：', event.data);
    const messageList = store.getters.messageListInfo.messageList
    const receivedList = (event.data || []).filter(newMessage => {
        return !messageList.some(message => newMessage.ID === message.ID || newMessage.conversationID !== store.getters.currentConversation.conversationID)
    })
    if (store.getters.currentConversation.conversationID) {
        tim.setMessageRead({ conversationID: store.getters.currentConversation.conversationID });
        store.commit('INSERT_MESSAGE_LIST', receivedList)
        store.commit('SET_NEED_BOTTOM', true)
    }
};

// SDK 收到消息被修改的通知，消息发送方可通过遍历 event.data 获取消息列表数据并更新页面上同 ID 消息的内容。
export let onMessageModified = function (event) {
    // event.data - 存储被修改过的 Message 对象的数组 - [Message]
    console.log('修改消息列表更新：', event.data);
    store.commit('INSERT_MESSAGE_LIST', event.data || [])
};

// SDK 收到消息被撤回的通知，可通过遍历 event.data 获取被撤回的消息列表数据并渲染到页面，如单聊会话内可展示为 "对方撤回了一条消息"；群聊会话内可展示为 "XXX撤回了一条消息"。
export let onMessageRevoked = function (event) {
    // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
    console.log('撤回的消息：', event.data);
    // store.dispatch('getMessageList')
};

// SDK 收到对端已读消息的通知，即已读回执。可通过遍历 event.data 获取对端已读的消息列表数据并渲染到页面，如单聊会话内可将己方发送的消息由“未读”状态改为“已读”。
export let onMessageReadByPeer = function (event) {
    // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
    console.log('端已读：', event.data);
    const readList = event.data || []
    readList.forEach(item => {
        store.getters.messageListInfo.messageList.forEach(message => {
            if(message.ID === item.ID) {
                message.isPeerRead = true
            }
        })
    })
};

// SDK 收到了群消息的已读回执（v2.18.0起支持）
export let onMessageReadReceiptReceived = function (event) {
    // event.data - 存储消息已读回执信息的数组
    console.log('群已读：', event.data);
    const readReceiptInfoList = event.data;
    readReceiptInfoList.forEach((item) => {
        const { groupID, messageID, readCount, unreadCount } = item;
        const message = tim.findMessage(messageID);
        if (message) {
            if (message.readReceiptInfo.unreadCount === 0) {
                // 全部已读
            } else {
                // message.readReceiptInfo.readCount - 消息最新的已读数
                // 如果想要查询哪些群成员已读了此消息，请使用 [getGroupMessageReadMemberList] 接口
            }
        }
    });
};

// 会话列表更新，event.data 是包含 Conversation 对象的数组
export let onConversationListUpdated = function (event) {
    console.log('userList:', event.data); // 包含 Conversation 实例的数组
    if(!isReady) {
        return
    }
    store.commit('SET_USER_LIST', event.data)
    store.commit('SET_TOTAL_UNREAD_MESSAGE_COUNT', tim.getTotalUnreadMessageCount())
};

// SDK 群组列表更新时触发，可通过遍历 event.data 获取群组列表数据并渲染到页面
export let onGroupListUpdated = function (event) {
    console.log(event.data);// 包含 Group 实例的数组
};

// 群属性更新时触发，可通过 event.data 获取到更新后的群属性数据（v2.14.0起支持）
export let onGroupAttributesUpdated = function (event) {
    const groupID = event.data.groupID // 群组ID
    const groupAttributes = event.data.groupAttributes // 更新后的群属性
    console.log(event.data);
};

// 创建话题时触发（v2.19.1起支持）
export let onTopicCreated = function (event) {
    const groupID = event.data.groupID // 话题所属社群 ID
    const topicID = event.data.topicID // 话题 ID
    console.log(event.data);
};

// 删除话题时触发（v2.19.1起支持）
export let onTopicDeleted = function (event) {
    const groupID = event.data.groupID // 话题所属社群 ID
    const topicIDList = event.data.topicIDList // 删除的话题 ID 列表
    console.log(event.data);
};

// 话题资料更新时触发（v2.19.1起支持）
export let onTopicUpdated = function (event) {
    const groupID = event.data.groupID // 话题所属社群 ID
    const topic = event.data.topic // 话题资料
    console.log(event.data);
};

// 自己或好友的资料发生变更时触发，event.data 是包含 Profile 对象的数组
export let onProfileUpdated = function (event) {
    console.log(event.data); // 包含 Profile 对象的数组
};

// SDK 黑名单列表更新时触发
export let onBlacklistUpdated = function (event) {
    console.log(event.data); // 我的黑名单列表，结构为包含用户 userID 的数组
};

// 好友列表更新时触发
export let onFriendListUpdated = function (event) {
    console.log(event.data);
}

// 好友分组列表更新时触发
export let onFriendGroupListUpdated = function (event) {
    console.log(event.data);
}

// FRIEND_APPLICATION_LIST_UPDATED
export let onFriendApplicationListUpdated = function (event) {
    // friendApplicationList - 好友申请列表 - [FriendApplication]
    // unreadCount - 好友申请的未读数
    const { friendApplicationList, unreadCount } = event.data;
    // 发送给我的好友申请（即别人申请加我为好友）
    const applicationSentToMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_TO_ME);
    // 我发送出去的好友申请（即我申请加别人为好友）
    const applicationSentByMe = friendApplicationList.filter((friendApplication) => friendApplication.type === TIM.TYPES.SNS_APPLICATION_SENT_BY_ME);
};

// 用户被踢下线时触发
export let onKickedOut = function (event) {
    console.log(event.data.type);
    // TIM.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一帐号，多页面登录被踢)
    // TIM.TYPES.KICKED_OUT_MULT_DEVICE(同一帐号，多端登录被踢)
    // TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期)
    // TIM.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出。v2.20.0起支持)
};

// 网络状态发生改变
export let onNetStateChange = function (event) {
    // v2.5.0 起支持
    // event.data.state 当前网络状态，枚举值及说明如下：
    // TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
    // TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
    // TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
};
import project from '@/utils/project'
import Cookies from 'js-cookie'

let subscribeMutation = {
    SET_COUNTRY_INFO({store, state, payload}){
        let countryInfo = payload || {};
        console.log(`SET_COUNTRY_INFO: `, countryInfo)
        project.setDefaultSetting({
            number: {
                // precision: countryInfo.currencyScale || 0,
                thousand: countryInfo.digitGroupingSymbol || ",",
                decimal : countryInfo.decimalPointCharacter || "."
            },
            currency: {
                symbol: countryInfo.currencySymbol || '',
                precision: countryInfo.currencyScale || 0,
                thousand: countryInfo.digitGroupingSymbol || ",",
                decimal : countryInfo.decimalPointCharacter || "."
            }
        })
        Cookies.set('countryId', countryInfo.id)
    },
    SET_INFO({store, state, payload}){

    }
}

export default store => {
    store.subscribe((mutation, state) => {
        let fn = subscribeMutation[mutation.type];
        if (typeof fn !== 'function'){
            return;
        }

        try {
            fn({state, payload: mutation.payload, store});
        } catch (err){
            console.log(err);
        } 
    });
}

//基准大小
const baseSize = 37.5

function setRem() {
    // 屏幕物理分辨率的宽 window.screen.width; //
    let screenWidth = document.documentElement.clientWidth
    // 当前页面宽度相对于 720 宽的缩放比例，且在屏幕宽度大于 720px 的时候不再继续放大
    if (screenWidth <= 720){
        const salepro = screenWidth / 720
        // 设置页面根节点字体大小
        document.documentElement.style.fontSize = (baseSize * Math.min(salepro, 2)) + 'px'
    }else{
        document.documentElement.style.fontSize = baseSize + 'px'
    }

    // console.log("111:", document.documentElement.clientWidth, window.screen.width, window.devicePixelRatio)
    // console.log("222:", document.documentElement.style.fontSize)
}


// 初始化
// document.ready = function(){
//     setRem()
// }
window.addEventListener("pageshow", function(){
    setRem()
})
document.addEventListener("DOMContentLoaded", function(){
    setRem()
})
// 改变窗口大小时重设 rem
window.onresize = function () {
    setRem()
}
import http from '@/utils/request'
import base_url from './config'


export function login(res) {
    return http.post({
        url: base_url + '/user/login/ngWholesalerLogin',
        data: res
    })
    
}

export function logout(res) {
    return http.post({
        url: base_url + '/auth/token/logout',
        data: res
    })
}


export function changePassword(res) {
    return http.post({
        url: base_url + '/user/user/changePassword',
        data: res
    })
}

// 腾讯IM获取userId和userSig
export function getUsersig() {
    return http.get({
        url: base_url + '/notify/tencenYun/generateUserSig',
        data: { identityType: 3 },
    })
}
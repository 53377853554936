const product = {
    state: {
        // 求购商品信息
        quotesProduct: null,
        // 首页是否要强制刷新
        refreshHome: 0,
        // 当前关闭的求购订单ID
        currentCloseRFQID: null,
    },
    mutations: {
        SET_QUOTES_PRODUCT: (state, quotesProduct) => {
            state.quotesProduct = quotesProduct
        },
        SET_REFRESH_HOME: (state, refreshHome) => {
            state.refreshHome = refreshHome
        },
        SET_CURRENT_CLOSE_RFQ_ID: (state, id) => {
            state.currentCloseRFQID = id
        }
    },
    actions: {
        setQuotesProduct({ commit }, quotesProduct) {
            commit('SET_QUOTES_PRODUCT', quotesProduct)
        },
        setRefreshHome({ commit }, refreshHome) {
            commit('SET_REFRESH_HOME', refreshHome)
        },
        setCurrentCloseRFQID({ commit }, id) {
            commit('SET_CURRENT_CLOSE_RFQ_ID', id)
        }
    }
}

export default product


import lang from "./lang/index"
import login from "./login/index"
import simbol from "./simbol/index"
import unit from "./unit/index"

export default {
    lang,
    login,
    simbol,
    unit
}

const app = {
    state: {
        device: 'desktop',
        language: 'en',
        axiosPromiseArr:[] //增加cancelToken  在请求没有结束的时候，点击tab或者切换页面，结束上一次的请求，吧请求储存到vuex中
    },
    mutations: {
        add_AxiosPromiseArr(state, item) { 
            state.axiosPromiseArr.push(item)
        },
        clear_AxiosPromiseArr(state,index) {
            delete state.axiosPromiseArr[index]
            // state.axiosPromiseArr.splice(index, 1)
            
        },
    },
    actions: {
        add_AxiosPromiseArr({ commit, state },cancel) {
            return new Promise((resolve, reject) => {
                commit('add_AxiosPromiseArr', cancel )
            })
        },
        clear_AxiosPromiseArr({ commit, state }, index) { 
            commit('clear_AxiosPromiseArr', index )
        }
    }
}

export default app

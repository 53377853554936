import Md5 from "js-md5"

// 默认签名key
export const DEFAULT_SIGN_SECRET_KEY = "egatee_h5_secret_key_NFXwQ6Rl"
/**
 * 根据参数生成数字签名
 * null、undefined、空字符串等不参与签名
 * @param {*} params 
 * @param {*} secretKey 
 * @returns {string} signStr
 */
export function getSign(params, secretKey=DEFAULT_SIGN_SECRET_KEY) {
    if (typeof params === 'string') {
        return paramsStrSort(params, secretKey)
    } else if (typeof params === 'object' && String(params) === '[object Object]') {
        let sortKey = Object.keys(params).filter(key => !!params[key]).sort()
        const paramsArr = sortKey.map(key => {
            if(params[key]) {
                return `${key}=${params[key]}`
            }
        })
        return paramsStrSort(paramsArr.join('&'), secretKey)
    }
    return ""
}
function paramsStrSort(paramsStr, secretKey) {
    var urlStr = paramsStr.split('&').sort().join('&');
    return Md5(`${Md5(urlStr)}&key=${secretKey}`)
}
/**
 * 验证签名是否一致
 * @param {*} params 
 * @param {*} sign 
 * @param {*} secretKey 
 */
const SIGN_KEY = "sign"
export function checkSign(params, sign, secretKey=DEFAULT_SIGN_SECRET_KEY) {
    const paramsObj = Object.assign({}, params)
    
    // sign不参与签名
    if(Object.hasOwnproperty.call(paramsObj, SIGN_KEY)) {
        if(!sign) {
            sign = paramsObj[SIGN_KEY]
        }
        delete paramsObj[SIGN_KEY]
    }

    const signStr = getSign(paramsObj, secretKey)
    return signStr === sign
}

// 获取16位随机字符串
export const createNonceStr = (len = 16) => {
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let nums = "";
    for (let i = 0; i < len; i++) { //这里是几位就要在这里不改变
        let id = parseInt(Math.random() * 61);
        nums += chars[id];
    }
    return nums;
}
/**
 * 接受 空格、& 隔开格式的参数串 暂时只考虑1级的情况
 * @param {*} paramStr 
 * @param {*} split 
 * @returns 
 */
export function toParamObj(paramStr, split) {
    if (!paramStr) { return {}; }
    let param = {};
    let type = typeof (param);
    if (type === "string") {
        let paramList = [];
        if (paramStr.indexOf("&") !== -1) {
            paramList = paramStr.split("&")
        } else {
            paramList = paramStr.split(" ")
        }
        paramList.map((str) => {
            let arr = str.split("=");

            param[arr[0]] = arr[1];
        })
    }
    return paramStr;
}


/**
 * 参数对象转为字符串
 * @param {*} param 
 * @param {*} key 
 * @param {*} encode 
 * @returns 
 */
export const toParamStr = (param, key, encode) => {
    if (param == null) { return ''; }
    var paramStr = '';
    var t = typeof (param);
    if (t == 'string' || t == 'number' || t == 'boolean') {
        paramStr += ' ' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
    } else {
        for (var i in param) {
            var k = key == null ? i : key + (param instanceof Array ? '[]' : '.' + i);
            paramStr += toParamStr(param[i], k, encode);
        }
    }
    return paramStr;
};


// 默认要被处理的字符
const regExp = new RegExp(/[\s|\-|'|&|(|)|/|?|%|#]/, 'g')
/**
 * 正则替换字符
 * @param {*} str 原始字符串
 * @param {*} replaceStr 目标字符
 * @param {*} reg 正则表达式
 * @returns 
 */
export const replaceSpecialCharacters = (str, replaceStr, reg = null) => {
    if(!str) {
        return ''
    }
    
    return str.replace(reg || regExp, replaceStr)
}

/**
 * 字符串根据位数用空格隔开
 * 场景：银行卡、手机号等展示
 * 银行卡：1234 2341 4325 4564 123
 * 手机号：137 1234 1231
 * @param str string|number
 * @param len 
 * @param reverse 手机号传true 从后面往前拆分
 */
export const separatedBySpace = (str, reverse=false, len=4) => {
    if(!str) {
        return ""
    }
    const reverseFn = (s) => {
        return String(s).split('').reverse().join('')
    }
    let temp = reverse ? reverseFn(str) : String(str)

    const reg = new RegExp(`(.{${len}})`, 'g')
    let value = temp.replace(reg, '$1 ').trim()
    return reverse ? reverseFn(value) : value
}
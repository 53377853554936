import qs from 'qs'

const parseURL = (url) => {
    let path = '';
    let search = '';
    const firstWildCardIndex = url.indexOf('?');
    if(firstWildCardIndex > 0){
        path = url.substring(0, firstWildCardIndex)
        search = url.substring(firstWildCardIndex + 1);
    }else if(firstWildCardIndex == 0){
        search = url.substring(firstWildCardIndex + 1)
    }else{
        path = url;
    }
    return {
        path,
        search
    }
}

/**
 * @function checkUrl 检查url是否合法
 * @param url 要检查的链接
 * @returns {boolean} 结果
 * */
export const checkUrl = (url) => {
    // eslint-disable-next-line no-useless-escape
    let pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&amp;//=]*)/;
    return pattern.test(url)
}

export const buildURL = (path, searchObj, isEncode = true) => {
    let search = qs.stringify(searchObj, {
        encode: isEncode
    });
    if(search){
        search = `?${search}`
    }
    return `${path}${search}`;
}

export const getURLQuery = (url) => {
    const {search} = parseURL(url);
    return search ? qs.parse(search) : {};
}

export const addOrUpdateURLQuery = (url, key, val, isEncode = true) => {
    const { path } = parseURL(url);
    let query = getURLQuery(url);
    query[key] = val;
    return buildURL(path, query, isEncode);
}

export const deleteURLQuery = (url, key, isEncode = true) => {
    const { path } = parseURL(url);
    let query = getURLQuery(url);
    if(typeof query[key] !== 'undefined'){
        delete query[key];
    }
    return buildURL(path, query, isEncode);
}

/**
 * 删除路由query对象中key并返回删除后的
 * @param {*} query
 * @param {*} key
 * @returns
 */
export const deleteQueryByKey = (query, key) => {
    if(!query) {
        return {}
    }
    let q = Object.assign({}, query)
    delete q[key]
    return q;
}

/**
 * 使用a标签点击打开窗口或替换窗口
 * @param {*} uri 链接地址
 * @param {*} target a标签的target属性，默认值_self
 * @param {*} removeAfterDuration a标签多长时间后被移除, 单位毫秒, 默认300ms
 */
export const evokeByTagA3 = (uri, target='_self', removeAfterDuration=300) => {
    const body = document.body;
    const tagA = document.createElement('a');
    tagA.setAttribute('href', uri);
    tagA.setAttribute('rel', 'noreferrer');
    const randomNumber = Math.floor(Math.random() * 100 + 1)
    target = 'g_page_newer' + randomNumber
    if(target){
        tagA.setAttribute('target', target);
    }
    tagA.style.display = 'none'; // append 在 android 6 系统中有兼容性问题
    body.appendChild(tagA);
    tagA.click();
    setTimeout(() => {
        if(body.contains(tagA)){
            body.removeChild(tagA);
        }
    }, removeAfterDuration || 300);
    console.log(`user click tag : ${tagA.outerHTML}`)
}

export const evokeByTagA = (uri, strWindowName, strWindowFeatures='') => {
    const randomNumber = Math.floor(Math.random() * 100 + 1)
    strWindowName = 'g_page_newer' + randomNumber
    strWindowFeatures = `noreferrer popup ${strWindowFeatures}`
    let windowObjectReference = window.open(uri, '_blank', strWindowFeatures)
    if(windowObjectReference){
        windowObjectReference.opener = null;
        windowObjectReference.focus();
    }
}


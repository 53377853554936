export const langConfig = {
    'zh-CN' : {
        extLang: 'zh-CN',
        macroLang: 'zh'
    },
    'en-US' : {
        extLang: 'en-US',
        macroLang: 'en'
    },
    'my': {
        extLang: 'my-MM',
        macroLang: 'my'
    },
    'pt': {
        extLang: 'pt-AO',
        macroLang: 'pt'
    },
    'fr' : {
        extLang: 'fr-FR',
        macroLang: 'fr'
    },
    'ha' : {
        extLang: 'ha',
        macroLang: ''
    },
    'sw' : {
        extLang: 'sw',
        macroLang: ''
    }
}

/**
 * 富文本编辑框 ueditor 的多语言配置对应
 * { 当前系统语言syslang => ueditor的语言lang }
 * 
 * ueditor的多语言配置路径： public\ueditor\lang
 * by Lexie Wen at 2022-06-09
*/
export const ueditorLangConfig = {
    'en-US':    'en',
    'zh-CN':    'zh-cn',
    'fr' :      'en',
    'ha' :      'en',
    'sw' :      'en',
}

export function getUserAgent() {
    return window.navigator.userAgent;
}

export function isJdeApp() {
    // JDE App ua
    //Mozilla/5.0 (Linux; Android 7.1.2; SM-G9810 Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/92.0.4515.131 Mobile Safari/537.36;jde
    let ua = getUserAgent()
    return ua.indexOf('jde') >= 0
}

export function isEgateeApp() {
    return isEgateeAppEnv() || isTomeApp()
}

export function isEgateeAppEnv() {
    // Egatee App ua
    // Mozilla/5.0 (Linux; Android 7.1.2; SM-G9810 Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/92.0.4515.131 Mobile Safari/537.36;com.egatee.store.test 7.5.5.5;egatee
    let ua = getUserAgent()
    return ua.indexOf('egatee') >= 0
}

export function isFlutterEgateeApp() {
    // Egatee App ua
    // Mozilla/5.0 (Linux; Android 7.1.2; SM-G9810 Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/92.0.4515.131 Mobile Safari/537.36;com.egatee.store.test 7.5.5.5;egatee
    let ua = getUserAgent()
    console.log({ua});
    return ua.indexOf('flutter_egatee') > -1
}

export function isIosApp() {
    return isEgateeAppEnv() && isIOS()
}

export function isAndroidApp() {
    return isEgateeAppEnv() && isAndroid()
}

export function isTomeEnv() {
    return isTomeApp() || isTomeH5()
}

export function isTomeApp() {
    var ua = getUserAgent();
    return ua.indexOf('tome') >= 0
}

export function isTomeH5() {
    // return Cookies.get('app_identity') == 2
    return window.sessionStorage.getItem('app_identity')
}

export function isSimbaAppEnv() {
    var ua = getUserAgent();
    return ua.indexOf('transsion.shopnc') >= 0 ||
        ua.indexOf('egatee') >= 0 ||
        ua.indexOf('yiwill') >= 0 ||
        ua.indexOf('jde') >= 0 ||
        isTomeApp();
}

// android
export function isAndroid() {
    let ua = getUserAgent()
    return ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
}

// ios
export function isIOS() {
    let ua = getUserAgent()
    return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

// flutter app
export function isFlutter(ctx) {
    let ua = getUserAgent(ctx)
    return ua.indexOf('flutter') > -1;
}
export function isWindows(ctx) {
    let ua = getUserAgent(ctx)
    return ua.indexOf('Win') > -1
}

export function isLinux(ctx) {
    let ua = getUserAgent(ctx)
    return ua.indexOf('Linux') > -1
}

export function getPlatform(ctx) {
    if(isAndroid(ctx)) {
        return "Android"
    }
    if(isIOS(ctx)) {
        return "IOS"
    }
    if(isWindows(ctx)) {
        return "Windows"
    }
    if(isLinux(ctx)) {
        return "Linux"
    }
    return "Unknow"
}
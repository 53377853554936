import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';



import zhCN from 'vant/es/locale/lang/zh-CN';



import enLocale from './en/index.js'
import zhLocale from './zh/index.js'
import myLocale from './my/index.js'
import ptLocale from './pt/index.js'
import { initExtension } from './extension.js'
import { addLangAtHtml } from '@/utils/langFn'
import { getStore, setStore } from '@/utils/store'
import { langConfig } from '@/lang/langConfig'

Vue.use(VueI18n)

const messages = {
    'en-US': {
        ...enLocale
    },
    'my' : {
        ...myLocale,
    },
    'pt': {
        ...ptLocale
    },
    'zh-CN': {
        ...zhLocale
    }
}

const enLang = langConfig['en-US'].extLang
const zhLang = langConfig['zh-CN'].extLang
const myLang = langConfig['my'].extLang
const ptLang = langConfig['pt'].extLang
// let navigatorLang = navigator.language || navigator.browserLanguage || navigator.userLanguage
let navigatorLang = "en"

if (navigatorLang.indexOf('en') != -1) {
    navigatorLang = enLang
} else if ( navigatorLang.indexOf('zh') != -1) {
    navigatorLang = zhLang
} else if ( navigatorLang.indexOf('my') != -1) {
    navigatorLang = myLang
} else if ( navigatorLang.indexOf('pt') != -1) {
    navigatorLang = ptLang
} else {
    navigatorLang = enLang
}

// localstorage中没有，取浏览器默认语言，需匹配项目已配置的多语言，否则默认英语
// const storeLang = getStore({ name: 'sysLang' }) || navigatorLang || "en"
const storeLang = "en"
// debugger
!getStore({ name: 'sysLang' }) && setStore({ name: 'sysLang', content: storeLang, type: 'local' })

//根据系统语言加载vant 语言包
if (storeLang.indexOf('en') != -1) {
    Locale.use('en-US', enUS);
} else if ( storeLang.indexOf('zh') != -1) {
    Locale.use('zh-CN', zhCN);
} else {
    Locale.use('en-US', enUS);
}
addLangAtHtml(storeLang)

const i18n = new VueI18n({
    locale: storeLang,
    messages
})

initExtension(i18n);
export default i18n

import { isFunc } from "@/utils/type"
import { getCountryByStore } from '@/utils/auth'
let constantCollection = {};

constantCollection.isProduction = process.env.NODE_ENV === 'production';

const getterDefaultFn = (propName, obj) => {
    if (propName == 'PASSPORT_BASE_URL' && !constantCollection.isProduction){
        return `${window.location.origin}/`;
    }
    return window[propName] || "";
}

let defineReadonlyProperty = (propertyName, getterFn) => {
    Object.defineProperty(constantCollection, propertyName, {
        get() {
            if(isFunc(getterFn)){
                return getterFn(propertyName, constantCollection)
            }else{
                return getterDefaultFn(propertyName, constantCollection)
            }
        },
        configurable: false,
        enumerable: true
    });
};

[
    'MMDC_BASE_URL',
    'JDE_BASE_URL',
    'PASSPORT_BASE_URL',
    'SELLER_BASE_URL',
    'COUNTRY_BASE_URL',
    'ADMIN_BASE_URL',
    'API_BASE_URL',
    'PAY_BASE_URL',
    'IMAGE_BASE_URL',
    'HOME_URL',
    'EGATEE_BASE_URL_CUSTOMIZE_BY_COUNTRY',
    'EGATEE_BASE_URL',
    'S3_IMG_BASE_URL_LIST',
    'VCONSOLE',
    'MAINTENANCE_BULLETIN'
].forEach((item) => defineReadonlyProperty(item));

/**
 * 设置只读的国家私有化BaseURL；
 * 规则：优先获取根据countryId的配置获取私有化域名，否则取EGATEE_BASE_URL
 */
constantCollection.getEgateeCountryBaseUrl = () => {
    console.log(`call getEgateeCountryBaseUrl  `)
    const cid = getCountryByStore()
    if(cid){
        const customizeEgateeCountryUrlInfo = constantCollection.EGATEE_BASE_URL_CUSTOMIZE_BY_COUNTRY
        if(!customizeEgateeCountryUrlInfo){
            console.warn(`
            暂未启用egatee国家私有化域名配置，如果需要使用请先到config.js文件中配置：
                例如：
                var EGATEE_BASE_URL_CUSTOMIZE_BY_COUNTRY = {
                    3: correctUrlToSameProtocal('egatee.ng/'),
                }
            说明：对象key值为国家id
            `)
        }else{
            if(customizeEgateeCountryUrlInfo[cid]){
                return customizeEgateeCountryUrlInfo[cid]
            }else{
                console.warn(`
                Egatee H5国家站点：countryId为${cid}的国家未开启国家私有化域名，若要开启请先到config.js文件中配置：
                    例如：
                    var EGATEE_BASE_URL_CUSTOMIZE_BY_COUNTRY = {
                        ${cid}: correctUrlToSameProtocal('egatee.xxx/'),
                    }
                `)
            }
        }
    }
    return constantCollection.EGATEE_BASE_URL
}

export default constantCollection;

import axios from 'axios'
import { getToken } from '@/utils/auth'
import codeStatus from '@/utils/errorCode'
import store from '../store'
// var codeStatus = require('@/utils/code.js');
import router from '@/router'
import { langConfig } from '@/lang/langConfig'
import Cookies from 'js-cookie'
import { getStore, setStore } from '@/utils/store'
import { Notify } from 'vant';
import project from '@/utils/project'
import { Toast } from 'vant';
import { isTomeEnv } from './common/env'
import CONSTANT from '@/utils/project/constant'

// import NProgress from 'nprogress' // progress bar
// 创建axios实例
const service = axios.create({
    baseURL: process.env.BASE_API, // api 的 base_url
    timeout: 500000, // 请求超时时间
    maxContentLength: 15 * 1024 * 1024,
    maxBodyLength:15 * 1024 * 1024,
})

// request拦截器  request  客户端向服务端发送端头
service.interceptors.request.use(
    config => {

        if( getToken()){
            config.headers.Authorization = getToken()
        }

        config.headers.SYSTEM_TYPE = config.headers.SYSTEM_TYPE === undefined ? 5 : config.headers.SYSTEM_TYPE
        config.headers.COUNTRY_ID = getStore({name:"userInfo"}) ? getStore({name:"userInfo"}).countryId : ''
        if (!config.headers.COUNTRY_ID) {
            config.headers.COUNTRY_ID = Cookies.get('countryId')
        }

        let sysLang = Cookies.get("jde-lang");
        sysLang = Object.prototype.hasOwnProperty.call(langConfig, sysLang) ? sysLang : "en-US";

        // 暂时移除 货币符号 MR姓名
        // let rmRes = Cookies.remove("countryCurrencySymbol")
        // let rmNameRes = Cookies.remove("MRContactName")

        if (sysLang) {
            let accepLang = sysLang + ( langConfig[sysLang].macroLang ? ',' + langConfig[sysLang].macroLang : '')
            config.headers['Accept-Language'] = accepLang
        }

        // 渠道App标识
        if(isTomeEnv()) {
            config.headers.AppIdentity = window.sessionStorage.getItem('app_identity') || 2
        }

        //增加cancelToken  在请求没有结束的时候，点击tab或者切换页面，结束上一次的请求，把请求全部储存到vuex中
        config.cancelToken = new axios.CancelToken(cancel => {
            store.dispatch('add_AxiosPromiseArr', { cancel })
        })
        return config
    },
    error => {
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器   response   服务端向客户端发送端
service.interceptors.response.use(
    response => {

        /**
         * code为非1是抛错 可结合自己业务进行修改
         */
        return new Promise((resolve, reject) => {

            const res = response.data
            const config = response.config
            // const status = Number(res.status) || Number(res.code) || 200;
            const status = Number(res.code)
            let message = res.msg || codeStatus.errorMsg[status] || codeStatus.errorMsg.default
            // 权限不足 跳转登陆
            // else if (res.status === codeStatus.powerCode) {
            //     store.dispatch('FedLogOut').then(() => {
            //         router.push({ path: '/' })
            //     })
            //     if (this.loading) {
            //         this.loading = false
            //     }
            //     reject(res)
            // }
            if (status == codeStatus.successCode) {
                resolve(res)

            } else if (status == codeStatus.businessCode) {
                Notify({ type: 'danger', message:message });
                reject(res)

            } else if (config.errorTip !== false && (status !== codeStatus.successCode || status == codeStatus.errorCode || status == 500 || status == codeStatus.failCode)) {
                // console.log(message)
                // Message({
                //     message: message,
                //     type: 'error'
                // })
                Notify({ type: 'danger', message:message });
                reject(res)
            } else {
                reject(res)
            }
        })
    },
    error => {
        return new Promise((resolve, reject) => {
            if (error.response == undefined) {
                Notify({ type: 'danger', message:'Network Anomaly' });
                reject(error.response)
            }
            const status = Number(error.response.status)
            if (CONSTANT.MAINTENANCE_BULLETIN && status == CONSTANT.MAINTENANCE_BULLETIN.status && CONSTANT.MAINTENANCE_BULLETIN.url) {
                window.location.replace(CONSTANT.MAINTENANCE_BULLETIN.url)
                error.response.data && Notify({ type: 'danger', message: error.response.data.msg });
                return;
            }
            const message = codeStatus.errorMsg[status] || codeStatus.errorMsg.default || error.message + 'code=' + error.code
            let filterDamageApiMessage = (url) =>{
                return !url.startsWith('/api/bi-provider');
            }
            let isShowMessage = filterDamageApiMessage(error.response.config.url);

            if (error.code == 'ECONNABORTED' || status == 504) {
                console.log('对不起，请求超时！请重新尝试')
                Notify({ type: 'danger', message:'Sorry, The request timed out!' });


            } else if (error.response.data.code == codeStatus.powerCode) {
                store.dispatch('LogOut').then(() => {
                    project.jumpLoginPageAndClearStore();
                })
            } else {
                console.log(message)
                // isShowMessage && Message({
                //     message: message,
                //     type: 'error',
                //     duration: 5 * 1000
                // })
                Notify({ type: 'danger', message:message });
            }
            reject(error.response)
        })
    }
)

let cancel
// let promiseArr = {}
const CancelToken = axios.CancelToken
export default {
    // get请求
    get(obj) {
        return new Promise((resolve, reject) => {
            service({
                method: 'get',
                url: obj.url,
                params: obj.data,
                headers: obj.headers || {},
                cancelToken: new CancelToken(c => {
                    cancel = c
                }),
                errorTip: obj.errorTip
            }).then(res => {
                resolve(res)
            }).catch(err => {

                reject(err)

            })
        })
    },
    // post请求
    post(obj) {
        return new Promise((resolve, reject) => {
            service({
                method: 'post',
                url: obj.url,
                data: obj.data,
                headers: obj.headers || {},
                cancelToken: new CancelToken(c => {
                    cancel = c
                }),
                errorTip: obj.errorTip
            }).then(res => {
                resolve(res)
            }).catch(err => {

                reject(err)

            })
        })
    },
    // delete请求
    delete(obj) {
        return new Promise((resolve, reject) => {
            service({
                method: 'delete',
                url: obj.url,
                data: obj.data,
                headers: obj.headers || {},
                cancelToken: new CancelToken(c => {
                    cancel = c
                })
            }).then(res => {
                resolve(res)
            }).catch(err => {

                reject(err)

            })
        })
    },
    // put请求
    put(obj) {
        return new Promise((resolve, reject) => {
            service({
                method: 'put',
                url: obj.url,
                data: obj.data,
                headers: obj.headers || {},
                cancelToken: new CancelToken(c => {
                    cancel = c
                })
            }).then(res => {
                resolve(res)
            }).catch(err => {

                reject(err)

            })
        })
    },
    // 导出下载
    export(obj) {
        return new Promise((resolve, reject) => {
            service({
                method: 'post',
                url: obj.url,
                data: obj.data,
                headers: obj.headers || {
                    'Content-Type': "application/json; charset=UTF-8"
                },
                responseType: "blob",
                cancelToken: new CancelToken(c => {
                    cancel = c
                })
            }).then(res => {
                resolve(res)
            }).catch(err => {

                reject(err)

            })
        })
    }
}

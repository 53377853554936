import validate from '@/utils/validate'

/**
 * 存储localStorage
 */
export const setStore = (params) => {
    let {
        name,
        content,
        type
    } = params
    let obj = {
        dataType: typeof (content),
        content: content,
        type: type,
        datetime: new Date().getTime()
    }

    /**
     * 规避有历史存入LocalStorage中的Bug，因此优先清楚一次本地存储
     * by linq at 2021/12/28
     */
    removeStore({name})
    
    if (type) { 
        window.localStorage.setItem(name, JSON.stringify(obj))
    } else {
        window.sessionStorage.setItem(name, JSON.stringify(obj)) 
    }
}
let getStorageObj = (storage, name) =>{
    try {
        let str = storage.getItem(name);
        if (str){
            return JSON.parse(str);
        }
    } catch (err) {
        console.error(err)
    }
    return null;
}
/**
 * 获取localStorage
 */

export const getStore = (params) => {
    let {
        name,
        debug
    } = params
    let content

    let obj = getStorageObj(window.sessionStorage, name);
    if (validate.validatenull(obj) || validate.validatenull(obj.content)) {
        obj = getStorageObj(window.localStorage, name);
    }
    if (validate.validatenull(obj)) {
        return
    }
    if (debug) {
        return obj
    }
    if (obj.dataType == 'string') {
        content = obj.content
    } else if (obj.dataType == 'number') {
        content = Number(obj.content)
    } else if (obj.dataType == 'boolean') {
        content = eval(obj.content)
    } else if (obj.dataType == 'object') {
        content = obj.content
    }
    return content
}
/**
 * 删除localStorage
 */
export const removeStore = params => {
    let {
        name
    } = params
    window.localStorage.removeItem(name)
    window.sessionStorage.removeItem(name)
}

/**
 * 获取全部localStorage
 */
export const getAllStore = (params) => {
    let list = []
    let {
        type
    } = params
    for (let i = 1; i <= window.sessionStorage.length; i++) {
        if (type) {
            list.push({
                name: window.sessionStorage.key(i),
                content: getStore({
                    name: window.sessionStorage.key(i),
                    type: 'session'
                })
            })
        } else {
            list.push(getStore({
                name: window.localStorage.key(i),
                content: getStore({
                    name: window.localStorage.key(i)
                })
            }))
        }
    }

    return list
}

/**
 * 清空全部localStorage
 */
export const clearStore = (params) => {
    let {
        type
    } = params
    if (type) {
        window.sessionStorage.clear()
        return
    }
    window.localStorage.clear()
}

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const vueRouter = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'sellerHome',
        component: () =>
            import( /* webpackChunkName: "home" */ '@/views/home')
    }, {
        path: '/addProduct',
        name: 'addProduct',
        component: () =>
            import( /* webpackChunkName: "product" */ '@/views/product/addProduct')
    }, {
        path: '/addRecommend',
        name: 'addRecommend',
        component: () =>
            import( /* webpackChunkName: "product" */ '@/views/product/addRecommend')
    }, {
        path: '/productList',
        name: 'productList',
        component: () =>
            import( /* webpackChunkName: "product" */ '@/views/product/productList')
    }, {
        path: '/productManage',
        name: 'productManage',
        component: () =>
            import( /* webpackChunkName: "product" */ '@/views/product/productManage')
    }, {
        path: '/login',
        name: 'login',
        component: () =>
            import( /* webpackChunkName: "login" */ '@/views/login')
    }, {
        path: '/reset',
        name: 'reset',
        component: () =>
            import( /* webpackChunkName: "reset" */ '@/views/login/resetPwd')
    }, {
        path: '/dataView',
        name: 'dataView',
        component: () =>
            import( /* webpackChunkName: "dataView" */ '@/views/dataView')
    }, {
        path: '/myShop',
        name: 'myShop',
        component: () =>
            import( /* webpackChunkName: "shop" */ '@/views/shop/myShop')
    }, {
        path: '/personal',
        name: 'personal',
        component: () =>
            import( /* webpackChunkName: "personal" */ '@/views/personal/index')
    }, {
        path: '/editBanner',
        name: 'editBanner',
        meta: {
            keepAlive: true
        },
        component: () =>
            import( /* webpackChunkName: "shop" */ '@/views/shop/editBanner')
    }, {
        path: '/IM',
        name: 'IM',
        alias: ['/IM_SMS', '/IM_WA'],
        component: () =>
            import( /* webpackChunkName: "im" */ '@/views/IM')
    }, {
        path: '/chat',
        name: 'chat',
        component: () =>
            import( /* webpackChunkName: "im" */ '@/views/IM/chat')
    }, {
        path: '/searchMessage',
        name: 'searchMessage',
        component: () =>
            import( /* webpackChunkName: "im" */ '@/views/IM/searchMessage')
    }, {
        path: '/video',
        name: 'video',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/views/video/index')
    },
    {
        path: '/showcase',
        name: 'showcase',
        component: () => 
            import( /* webpackChunkName: "showcase" */ '@/views/showcase/index')
    },
    {
        path: '/showcase/preview',
        name: 'showcase-preview',
        component: () => 
            import( /* webpackChunkName: "showcase" */ '@/views/showcase/preview/index')
    },
    {
        path: '/identityChoice',
        name: 'identityChoice',
        component: () =>
            import( /* webpackChunkName: "login" */ '@/views/login/identityChoice')
    },
    {
        path: '/businessCard',
        name: 'businessCard',
        component: () =>
            import( /* webpackChunkName: "im" */ '@/views/businessCard/index')
    },
    {
        path: '/rfq/myQuotes',
        name: 'myQuotes',
        component: () =>
            import( /* webpackChunkName: "rfq" */ '@/views/rfq/myQuotes')
    },
    {
        path: '/rfq/detail',
        name: 'quotesDetail',
        component: () =>
            import( /* webpackChunkName: "rfq" */ '@/views/rfq/detail')
    },
    {
        path: '/myOrders',
        name: 'myOrders',
        component: () =>
            import( /* webpackChunkName: "order" */ '@/views/myOrders')
    },
    {
        path: '/service/subscription',
        name: 'subscription',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/views/service/subscription')
    }
    ]
})


export default vueRouter;

import Numeral  from '@/utils/numeral'

export let {
    toFixed,
    formatTime,
    isEqual,
    setDefaultSetting,
    formatMoney,
    formatCurrencyMoney,
    formatNumber,
    formatPercentages,
    unformat
} = Numeral;



export default {
    title: "System Login",
    Login: "လော့ဂ်အင်",
    username: "သုံးစွဲသူအိုင်ဒီ",
    password: "စကားဝှက်",
    originalPassword: "စကားဝှက်အဟောင်း",
    confirmPassword: "စကားဝှက်အတည်ပြုခြင်း",
    any: "ပေါ့ပေါ့ပါးပါး ဖြည့်ပါ။",
    code: "အတည်ပြုရန်ကုတ်",
    thirdparty: "ဖြင့် ဝင်ရောက်ပါ။",
    thirdpartyTips: "ဒေသအလိုက် အတုယူ၍မရပါ၊ သင့်ကိုယ်ပိုင်လုပ်ငန်းကို ပေါင်းစပ်ပါ။ ! !",
    BusinessDesk: "နိုင်ငံအလိုက်ဆိုဒ်",
    WelocomeLogin: "ကျေးဇူးပြု၍ ဝင်ရောက်ပါ။",
    loginUserName: "အကောင့်ဝင်ပါ။"
}
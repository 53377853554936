export default {
    title: "System Login",
    Login: "Log in",
    username: "User ID",
    password: "Password",
    originalPassword: "Old Password",
    confirmPassword: "Confirm Password",
    any: "Casually Fill in",
    code: "Verification Code",
    thirdparty: "Sign in with",
    thirdpartyTips: "Locally cannot simulate, please combine your own business! ! !",
    BusinessDesk: "Country Site",
    WelocomeLogin: "Login Please",
    loginUserName: "Login Account"
}
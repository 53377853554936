export default {
    single: "စာရင်း",
    home: "အိမ်",
    all: "အားလုံး",
    true: "ဟုတ်ကဲ့",
    false: "မရှိ",
    family: "အိမ်",
    list: "စာရင်း",
    gram: "ကုလားပဲ",
    Zhang: "ကွက်",
    reduce: "နှုတ်ယူ",
    plus: "ပေါင်းတယ်။",
    stage: "ဇာတ်ခုံ",
    theFirst: "ပထမဆုံး",
    yuan: "ယွမ်",
    full: "အပြည့်",
    hit: "ဒါဇင်",
    day: "နေ့",
    fracture: "ကျိုးသည်။",
    hour: "နာရီ",
    minute: "မိနစ်",
    second: "ဒုတိယ",
    piece: "ကွက်"
}
import CONSTANT from './constant'
import router from '@/router'
import store from '@/store'
import Vue from 'vue'
import {getToken} from '@/utils/auth'
import { isEgateeApp } from '../common/env'
import HybridHandler from '../common/hybridHandler'

export function jumpLoginPage() {
    if(isEgateeApp()) {
        const hybrid = new HybridHandler()
        hybrid.jumpToAndroidUrl(hybrid.pages.appLogin)
    } else {
        router.push({path: '/login'})
    }
}

/**
 * 清理缓存并跳转登录
 * by linq at 2021/12/15
 */
export function jumpLoginPageAndClearStore(){
    store.dispatch('LogOut').then(res => {
        clearTim()
        jumpLoginPage();
    })
}

/**
 * 清理tim状态
 */
export function clearTim() {
    if(Vue.prototype.$tim) {
        Vue.prototype.$tim.destroy()
        Vue.prototype.$tim = null
    }
    store.commit('SET_TIM_INFO', {})
    store.commit('SET_TOTAL_UNREAD_MESSAGE_COUNT', 0)
}


// 监听目标dom是否出现在可视区域
export const observerDom = (callback, options) => {
    if (!options.el) {
        console.error('IntersectionObserver api miss observe target dom')
        return
    }
    const ob = new IntersectionObserver(callback, options)
    ob.observe(options.el)
    return ob
}
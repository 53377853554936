<template>
  <div id="app">
        <keep-alive :include="routerName">
            <router-view />
        </keep-alive>
  </div>
</template>

<script>
import CONSTANT from '@/utils/project/constant'
export default {
    name: 'App',
    components: {

    },
    data(){
        return {
            routerName:"home,editBanner,addRecommend,myShop,myQuotes,Showcase"
        }
    },
    mounted() {
        if(window.location.host.indexOf('uat') > -1 || CONSTANT.VCONSOLE){
            this.loadConsole()
        }
    },
    methods:{
        loadConsole(){
            const initConsole = () => {
                console.log('vconsole初始化')
                let script1 = document.createElement('script');
                script1.type = 'text/javascript';
                script1.text = '(function(){var vConsole = new VConsole();})()';
                document.body.appendChild(script1);
            }

            this.loadScriptBySrcList(['https://cdn.jsdelivr.net/npm/vconsole@latest/dist/vconsole.min.js', 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js']).then(() => {
                console.log('vconsole加载成功');
                initConsole()
            }).catch(() => {
                console.error('vconsole加载失败');
            })
        },
        loadScriptBySrcList(srcList) {
            const loadScript = (src) => {
                return new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = src;
                    script.type = 'text/javascript';

                    script.onload = () => resolve(script);
                    script.onerror = (error) => {
                        document.body.removeChild(script);
                        reject(error);
                    };
                    document.body.appendChild(script);
                })
            }

            return Promise.race(srcList.map(src => loadScript(src)));
        }
    }
}
</script>

<style>
#app {
  font-family: Roboto-Medium,Roboto-Regular,Roboto-Light,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
html,
body {
    max-width: 720PX;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    background: #f5f6f8;
    position: relative;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    background-color:#f5f6f8
}
</style>

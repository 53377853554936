var getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    roles: state => state.user.roles,
    info: state => state.user.info,
    language: state => state.app.language,
    permissions: state => state.user.permissions,
    currencyScale: state => state.user.currencyScale,


    pageId: state => state.user.pageId,
    userinfotoken: state => state.user.userinfotoken,
    accountId: state => state.user.accountId,
    menu: state => state.user.menu,
    timeZoneUtil: state => state.user.timeZone,
    axiosPromiseArr: state => state.app.axiosPromiseArr,
    userList: state => state.tim.userList,
    currentConversation: state => state.tim.currentConversation,
    messageListInfo: state => state.tim.messageListInfo,
    referralMessageInfo: state => state.tim.referralMessageInfo,
    timInfo: state => state.tim.timInfo,
    firstLoad: state => state.tim.firstLoad,
    totalUnreadMessageCount: state => state.tim.totalUnreadMessageCount,
    needBottom: state => state.tim.needBottom,
    initOk: state => state.tim.initOk,
    unfinishedMessageListByconversationID: state => state.tim.unfinishedMessageListByconversationID,
    quotesProduct: state => state.product.quotesProduct,
    refreshHome: state => state.product.refreshHome,
    currentCloseRFQID: state => state.product.currentCloseRFQID,
}
export default getters

export default {
    title: "Início de sessão do sistema",
    Login: "Iniciar sessão",
    username: "ID do usuário",
    password: "Palavra-passe",
    originalPassword: "Palavra-passe antiga",
    confirmPassword: "Confirmar palavra-passe",
    any: "Preencher casualmente",
    code: "Código de verificação",
    thirdparty: "Entrar com",
    thirdpartyTips: "Não pode simular, por favor crie o seu próprio negócio.",
    BusinessDesk: "Local no país",
    WelocomeLogin: "Por favor inicie sessão",
    loginUserName: "Conta de login"
}
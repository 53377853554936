export default {
    single: "Lista",
    home: "Página Inicial",
    all: "Tudo",
    true: "Sim",
    false: "Não",
    family: "Página Inicial",
    list: "Lista",
    gram: "Grama",
    Zhang: "Pedaço",
    reduce: "Descontar/deduzir",
    plus: "Mais",
    stage: "Estado",
    theFirst: "O primeiro",
    yuan: "Unidade de conta",
    full: "Cheio",
    hit: "Dazon",
    day: "Dia",
    fracture: "Fractura",
    hour: "Hora",
    minute: "Minuto",
    second: "Segundo",
    piece: "Pedaço"
}

import http from '@/utils/request'
import base_url from './config'


export function getInfo (token) {
    return http.get({
        url: base_url + '/user/seller/info'
    })
}

//自定义的图片上传接口
export function customImgUpload (res) {
    return http.post({
        url: `${base_url}/base/file/upload/other`,
        data: res
    })
}



//上传视频
export function uploadVideo (res) {
    return http.post({
        url: `${base_url}/base/file/upload/seller_video`,
        data: res
    })
}

// 获取 IM 历史消息
export function getC2CHistoryMessageList(res) {
    return http.post({
        url: `${base_url}/notify/im/getC2CHistoryMessageList`,
        data: res
    })
}

// 获取 IM 历史会话
export const getUserSessionList = (res) => {
    return http.post({
        url: `${base_url}/notify/im/getUserSessionList`,
        data: res
    })
}

// 搜索消息 IM 历史会话
export const getUserSessionMsg = (res) => {
    return http.post({
        url: `${base_url}/notify/im/getUserSessionMsg`,
        data: res
    })
}


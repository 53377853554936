
let generateDataTypeFn = (type)=>{
    return function(s){
        return typeof s === type
    }
};


export const isBoolean = generateDataTypeFn('boolean')
export const isNumber = generateDataTypeFn('number')
export const isString = generateDataTypeFn('string')
export const isArray = Array.isArray
export const isUndefined = generateDataTypeFn('undefined')
export const isFunction = generateDataTypeFn('function')
export const isNull = type => type == null;

export const isObject = (s)=>{
    return generateDataTypeFn('object')(s) && !isNull(s) && !isArray(s);
}
export const isPromise = (s)=>{
    return !isUndefined(s) && s!== null && isFunction(s.then) && isFunction(s.catch);
}

export const deepClone = (d) => {
    var type = d
    var obj
    if (isArray(type)) {
        obj = []
    } else if (isObject(type)) {
        obj = {}
    } else {
        // 不再具有下一层次
        return d
    }
    if (isArray(type)) {
        for (var i = 0, len = d.length; i < len; i++) {
            obj.push(deepClone(d[i]))
        }
    } else if (isObject(type)) {
        for (var key in d) {
            if(Object.hasOwnProperty.call(d, key)) {
                obj[key] = deepClone(d[key])
            }
        }
    }
    return obj
}
/**
 * 多语言扩展
 */
let extensions = {
    /**
     * 例： 请输入"内容"
     * @description 
     * i18n.pleaseEnter('form.content')
     * @param {String} key 
     * @returns [zh] 请输入内容
                [en] Please enter content
     */
    pleaseEnter (key) {
        key = key || "label.content";
        let space = this.locale === 'zh' ? '' : ' '
        return this.t("placeholder.pleaseEnter", {key: this.t(key).toLowerCase()});
    },

    /**
     * 例： 请选择"内容"
     * @description 
     * i18n.pleaseSelect('form.content') 
     * zh 请选择内容
     * en Please select content
     * @param {String} key 多语言key
     * @returns 翻译好的语言文字
     */
    pleaseSelect (key) {
        key = key || "label.content"
        let space = this.locale === 'zh' ? '' : ' '
        return this.t("placeholder.pleaseSelectBase", {key: this.t(key).toLowerCase()})
    },

    /**
     * 例： 请输入正确的"内容"
     * @description 
     * i18n.pleaseEnterRight('form.content')
     * >> zh 请输入正确的内容
     * >> en Please enter the correct content
     * @param {String} key 多语言key
     * @returns 翻译好的语言文字
     */
    pleaseEnterRight (key) {
        key = key || "label.content"
        let space = this.locale === 'zh' ? '' : ' '
        return this.t("placeholder.pleaseEnterRightBase", {key: this.t(key).toLowerCase()})
    },
    /**
     * 多个多语言片段拼接
     * @description 
     * i18n.joiningTogether('label.pleaseEnter','form.content', ['', {key:''}], ['', [1,2,3]])
     * 参数只能是String 和 Array
     * @returns 翻译好的语言文字
     */
    joiningTogether () {
        let arr = [...arguments];
        let space = this.locale === 'zh' ? '' : ' ';
        let buffer = "";
        arr.map(val=>{
            let key = val;
            if (typeof val === "string"){
                key = [val]
            }
            buffer += this.t.apply(this, key).toLowerCase() + space
        })
        // 首字母大写
        return buffer.charAt(0).toUpperCase() + buffer.slice(1); 
    }
}
export function initExtension(i18n){
    i18n.__proto__ = Object.assign(i18n.__proto__, extensions)
}

export default {
    title: "系统登录",
    Login: "登录",
    username: "账号",
    password: "密码",
    originalPassword: "原密码",
    confirmPassword: "确认密码",
    any: "随便填",
    code: "验证码",
    thirdparty: "第三方登录",
    thirdpartyTips: "本地不能模拟，请结合自己业务进行模拟！！！",
    BusinessDesk: "国家站点",
    WelocomeLogin: "欢迎登录",
    loginUserName: "登录账号"
}
import router from './router'
import store from './store'
import { crmLogin, addCrmUser } from "./api/login"
import { validatenull } from '@/utils/validate'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getStore, setStore } from '@/utils/store'
const whiteList = ['/login', '/fromlogin'] // 不重定向白名单


router.beforeEach((to, from, next) => {

    // 缓冲设置
    /* if (to.meta.keepAlive === true) {
        to.meta.$keepAlive = true
    } else {
        if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
            to.meta.$keepAlive = true
        } else {
            to.meta.$keepAlive = false
        }
    } */
    // 观如上代码总结：  xiezhi 2021-12-06
    // to.meta.$keepAlive = !!to.meta.keepAlive;

    /* 渠道App标识 */
    if(to.query.app_identity) {
        window.sessionStorage.setItem('app_identity', to.query.app_identity)
    }

    // wenling 2021-12-17

    if (to.query.token) {
        removeToken()
        setStore({
            name: 'userInfo',
            content: {}
        })
        let token = to.query.token
        setToken(token)
        to.path !== '/login' && store.dispatch('GetInfo').then(res => {
            next()
        })



    } else if (getToken() != 'Basic enV1bDp6dXVs') {

        // if (store.getters.roles === null) {
        //     store.dispatch('GetMenu').then(res => {
        //         console.log(res)
        //         console.log('store.getters.menu', store.getters.menu)
        //     })
        //     store.dispatch('GetUserPermissionsInfo').then(res => { // 拉取用户信息

        //     })

        //     store.dispatch('GetInfo').then(res => { // 拉取用户信息
        //         if (to.path === '/login' && to.fullPath.indexOf('redirect') < 0) {
        //             next({ path: '/' })
        //         } else if (to.path === '/login' && to.fullPath.indexOf('redirect') > 0) {
        //             next({ path: '/' })
        //         } else if (to.path === '/fromlogin') {
        //             next({ path: '/' })
        //         } else {
        //             next()
        //         }
        //         console.log(res)
        //     }).catch((err) => {
        //         next()
        //     })
        // } else {

        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual'
        }

        to.path !== '/login' && store.dispatch('GetInfo').then(res => {

        })

        if (to.path === '/login' && to.fullPath.indexOf('redirect') != 0) {
            next({ path: '/' })
        } else if (to.path === '/fromlogin') {
            next({ path: '/' })
        } else {
            next()
        }
        // }
    } else {

        if (whiteList.indexOf(to.path) !== -1) {
            if (to.path == "/fromlogin") {
                if (to.query.token) {
                    let token = to.query.token
                    setToken(token)
                    next({ path: '/' })
                } else {
                    //跳转登录
                    next({ path: '/login' })
                }
            } else {
                next()
            }
        } else {
            //跳转登录
            next({ path: '/login' })
        }
    }


})
router.afterEach(() => {
})

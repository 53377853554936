// 时区处理公共类
export const TimeZone = (() => {
    const _tzUtils = Symbol('timeZoneUtils')

    /**
     * 内部类，不对外暴露的方法
     * 给时区对象提供计算功能
     */
    class TimeZoneUtils {

        constructor(timeZoneOffset) {
            this.timeZoneOffset = timeZoneOffset
        }

        /**
         * 
         * @param {String|Number} str 
         * @returns 
         */
        padLeftZero(str) {
            return ('00' + str).substr(str.length)
        }
        /**
         * 获取系统时间毫秒数相对utc偏移量
         * @param {*} date 
         * @returns
         */
        getSystemOffsetTime(date) {
            date = date || new Date();
            // 东八区 -480 * 60 * 1000
            return date.getTimezoneOffset() * 60 * 1000
        }

        /**
         * 获取当前国家时间毫秒数相对utc偏移量
         * @returns 
         */
        getCurrencyCountryOffsetTime() {
            return this.timeZoneOffset * 60 * 60 * 1000;
        }

        /**
         * 获取 当前国家 对应时间的 时间戳
         * @returns timestamp utc时间戳 + 当前国家毫秒数偏移量
         */
        getCurrencyCountryTime() {
            let date = new Date();
            //2. 计算当前国家时间戳偏移量
            let currencyCountryOffsetTime = this.getCurrencyCountryOffsetTime()
            //3. 计算当前国家时区时间时间戳
            return date.getTime() + currencyCountryOffsetTime;
        }

        /**
         * 用于new Date的当前国家时间戳
         * 当前国家对应时间戳 + 当前系统时区偏移时间戳
         * new Date会带上时区 具有迷惑行为
         * @returns 
         */
        getSystemCountryTime() {
            return this.getCurrencyCountryTime() + this.getSystemOffsetTime();
        }

        /**
         * 当前国家时间0点毫秒数
         * @returns timestamp
         */
        getCurrencyCountryDatetime() {
            return new Date(this.getSystemCountryTime()).setHours(0, 0, 0, 0)
        }
    }

    return class {
        countryCode; // UG
        tzDatabaseName; // Africa/Kampala
        timeZoneOffset; // 3 小时 注：国家信息中存储的是 3 而不是 +03:00 ±hh:mm格式 也不是 ±180 分钟

        constructor(countryCode, tzDatabaseName, timeZoneOffset) {
            // 初始化timeZoneUtils
            if (!this[_tzUtils] && this.timeZoneOffset !== timeZoneOffset) {
                this[_tzUtils] = new TimeZoneUtils(timeZoneOffset);
            }
            this.countryCode = countryCode;
            this.tzDatabaseName = tzDatabaseName;
            this.timeZoneOffset = timeZoneOffset;
        }

        /**
         * 判断系统时间日期是否在当前国家时间之前
         * 比较当前日期0点0分0秒
         * 
         * 例：Element DateTime组件禁用日期
         * @param {Number} timestamp utc时间戳
         * @param {Number} [countryTime] 当前国家日期毫秒数 可选
         * @param {String} [type] lt(小于|默认) gt(大于)
         * @returns {Boolean}
         */
        compareCountryDayByTime(timestamp, countryTime, type) {
            if (!timestamp) {
                throw new Error("[TimeZone][compareCountryDayByTime] => Error. timestamp is required!");
            }
            countryTime = countryTime || this[_tzUtils].getCurrencyCountryDatetime();
            type = type || "lt"

            return type === "lt" ? new Date(timestamp).setHours(0, 0, 0, 0) < new Date(countryTime).setHours(0, 0, 0, 0) : new Date(timestamp).setHours(0, 0, 0, 0) > countryTime;
        }

        /**
         * 当前国家时间字符串 - 转换 - 当前系统对应时区时间对象
         * 1635224325935 -> Tue Oct 26 2021 17:58:45 GMT+0800 (中国标准时间)
         * @param {String} dateStr yyyy-MM-dd hh:mm:ss
         * @return Date 系统当前时区时间对象
         */
        transformCountryTimeToSystemTime(dateStr, separator) {
            if (!dateStr) {
                throw new Error("[TimeZone][transformCountryTimeToSystemTime] => Error.The parameter date string is required")
            }
            // 2021-11-22 06:19:55
            let time = this.getTimeByFormatStr(dateStr, separator)
            // 1. 根据当前国家时区和时间，计算出对应的UTC时间戳
            let utcTime = time - this[_tzUtils].getCurrencyCountryOffsetTime() + (this[_tzUtils].getSystemOffsetTime() * -1);
            // new Date自动添加系统当前时区信息
            return new Date(utcTime);
        }

        /**
         * 当前国家对应时间字符串
         * @return {String} yyyy-MM-dd hh:mm:ss格式的时间字符串
         */
        getCountryDateStr(fmt = "yyyy-MM-dd hh:mm:ss") {
            return this.getFormatStrByTimestamp(this[_tzUtils].getSystemCountryTime(), fmt)
        }

        /**
         * 时间戳 -> 指定格式日期时间字符串
         * @param {Number} timestamp 时间戳毫秒数
         * @param {String} [fmt] 格式 yyyy-MM-dd hh:mm:ss
         */
        getFormatStrByTimestamp(timestamp, fmt) {
            if (!timestamp) {
                throw new Error("[TimeZone][getFormatStrByTimestamp] => Error. Timestamp is required!");
            }
            // 默认时间格式
            fmt = fmt || "yyyy-MM-dd hh:mm:ss";

            let date = new Date(timestamp);
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
            }
            let o = {
                'M+': date.getMonth() + 1,
                'd+': date.getDate(),
                'h+': date.getHours(),
                'm+': date.getMinutes(),
                's+': date.getSeconds()
            }
            for (let k in o) {
                if (new RegExp(`(${k})`).test(fmt)) {
                    let str = o[k] + ''
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this[_tzUtils].padLeftZero(str))
                }
            }
            return fmt
        }

        /**
         * 字符串时间 解析成 UTC时间戳
         * 
         * @param {String} str 格式[yyyy-MM-dd HH:mm:ss | yyyy/MM/dd HH:mm:ss | yyyy-MM-dd]等
         * @param {String} separator yyyy-MM-dd 中间的分隔符 默认-
         * @return {Number} time
         */
        getTimeByFormatStr(str, separator) {
            separator = separator || "-";
            if(!str){
                return false;
            }
            let strs = str.split(' ');

            // 处理年月日
            let dateStrs = strs[0].split(separator);
            let year = parseInt(dateStrs[0])
            // 处理月份为04这样的情况
            let month = dateStrs[1].indexOf('0') == 0 ? parseInt(dateStrs[1].substring(1)) - 1 : parseInt(dateStrs[1]) - 1
            let day = parseInt(dateStrs[2])

            // 处理时分秒
            let timeStrs = strs[1] ? strs[1].split(":") : [];
            let hours = parseInt(timeStrs[0]) || 0;
            let minutes = parseInt(timeStrs[1]) || 0;
            let seconds = parseInt(timeStrs[2]) || 0;
            let milliseconds = parseInt(timeStrs[3]) || 0;

            return new Date(year, month, day, hours, minutes, seconds, milliseconds).getTime();
        }

        /**
         * 
         * @param {*} timeZone IANA时区数据库中对应的时区标识
         * 如：本地时区标识 -> Intl.DateTimeFormat().resolvedOptions().timeZone
         * @returns 
         */
        getTimeForTimeZone(timeZone) {
            const now = new Date();
            const options = {
                timeZone: timeZone,
                hour12: false, // 使用24小时制
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            const formatter = new Intl.DateTimeFormat('en-US', options);
            return formatter.format(now);
        }
    }
})()

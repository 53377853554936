import http from '@/utils/request'
import base_url from './config'
// export function getProvince(res) {
//     res = res || store.getters.info.id;
//     return http.post({
//         url: `${base_url}/base/district/countryProvinceList/${res}`
//     })
// }

/**
 * 查询国家
 *
 * @returns
 */
export function getCountry() {
    return http.post({
        url: base_url + '/base/district/listCountry'
    })
}
/**
 * 根据国家查询省
 * @param countryID res
 * @returns
 */
export function getProvince(res) {
    return http.post({
        url: base_url + '/base/district/province/' + res
    })
}

/**
 * 根据国家查询省
 * @param countryID res
 * @returns
 */
export function countryProvinceList(res) {
    return http.post({
        url: base_url + '/base/district/countryProvinceList/' + res
    })
}

export function getCity(res) {
    return http.post({
        url: base_url + '/base/district/city/' + res
    })
}

export function getArea(res) {
    return http.post({
        url: base_url + '/base/district/area/' + res
    })
}


/**
 * 获取省市区2级
 */
export function getListtree(level, countryCode) {
    level = level || 3;
    countryCode = countryCode || ''
    return http.get({
        url: base_url + '/base/district/listtree?level=' + level + "&countryCode=" + countryCode
    })
}

//获取电话号码
export function queryCountrySettingByKey(res) {
    return http.post({
        url: `${base_url}/user/countrySetting/queryCountrySettingByKey`,
        data: res
    })
}

// 获取pageId
export const getconfigbykey = ( params) => {
    return http.get({
        url: base_url + '/base/businessconfig/getconfigbykey',
        data: params,
        errorTip: false,
    })
}
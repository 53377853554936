/**
 * Created by jiachenpan on 16/11/18.
 */

const validate = {
    isvalidUsername(rule, str, callback) {
        const valid_map = ['admin', 'editor']
        if (valid_map.test(str)) {
            callback()
        } else {
            callback(new Error())
        }

    },
    /* 合法uri*/
    validateURL(textval) {
        const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
        return urlregex.test(textval)
    },
    /* 小写字母*/
    validateLowerCase(rule, str, callback) {
        const reg = /^[a-z]+$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }

    },

    /**
     * 判断是否为空
     */
    validatenull(val) {
        if (typeof val === 'boolean') {
            return false
        }
        if (typeof val === 'number') {
            return false
        }
        if (val instanceof Array) {
            if (val.length == 0) {
                return true
            }
        } else if (val instanceof Object) {
            if (JSON.stringify(val) === '{}') {
                return true
            }
        } else {
            if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') {
                return true
            }
            return false
        }
        return false
    },
    /* 大写字母*/
    validateUpperCase(rule, str, callback) {
        const reg = /^[A-Z]+$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },

    /* 大小写字母*/
    validatAlphabets(rule, str, callback) {
        const reg = /^[A-Za-z]+$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },


    /* 身份证号(15位或18位，最后一位为校验位)*/
    validatIDCard(rule, str, callback) {
        const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },

    /* 密码(以字母开头，长度在6~18之间，只能包含字母、数字和下划线)*/
    validatPassword(rule, str, callback) {
        const reg = /^[a-zA-Z]\w{5,17}$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },
    /* 强密码(必须包含大小写字母和数字的组合，不能使用特殊字符，长度在 8-20 之间)*/
    validatStrongPassword(rule, str, callback) {
        const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,20}$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },

    /* 银行卡*/
    validatBankCard(rule, str, callback) {
        const reg = /^([1-9]{1})(\d{14}|\d{18})$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },

    /* 价格保留两位小数*/
    validatPrice(rule, str, callback) {
        const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
    },

    /* 正整数*/
    validatIntNumber(rule, str, callback) {
        const reg = /^[1-9]\d*$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
        // return reg.test(str)

    },

    /* 手机号*/
    validatPhone(rule, str, callback) {
        const reg = /^1\d{10}$$/
        if (reg.test(str)) {
            callback()
        } else {
            callback(new Error())
        }
        // return reg.test(str)

    },

    isURL(s) {
        return /^http[s]?:\/\/.*/.test(s)
    },

    /* 正整数和0 可以为空不填 */
    validatIntNumberCanBeNull(rule, str, callback) {
        const reg = /^[0-9]\d*$/
        if (reg.test(str) || str == "" || str == null) {
            callback()
        } else {
            callback(new Error())
        }
        // return reg.test(str)

    },

    /* 价格保留两位小数 可以为空不填 */
    validatPriceCanBeNull(rule, str, callback) {
        const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/
        if (reg.test(str) || str == "" || str == null) {
            callback()
        } else {
            callback(new Error())
        }
    },

    /**
     * 判断是否是外部链接
     * @param {string} path
     * @returns {Boolean}
     */
    isExternal(path) {
        return /^(https?:|mailto:|tel:)/.test(path)
    }


}

export default validate


import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import getters from './getters'
import tim from './modules/tim'
import product from './modules/product'
import projectPlugins from './plugin'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        user,
        tim,
        product
    },
    plugins: [projectPlugins],
    getters
})

export default store

export default {
    single: "List",
    home: "Home",
    all: "All",
    true: "Yes",
    false: "No",
    family: "Home",
    list: "List",
    gram: "Gram",
    Zhang: "Piece",
    reduce: "Deduct",
    plus: "Plus",
    stage: "Stage",
    theFirst: "Thefirst",
    yuan: "Yuan",
    full: "Full",
    hit: "Dazon",
    day: "Day",
    fracture: "Fracture",
    hour: "Hour",
    minute: "Minute",
    second: "Second",
    piece: "Piece"
}
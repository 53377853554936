import TIM from "tim-js-sdk";
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
import * as events from './events'
import Vue from "vue";
export let tim
export const initTim = function ({ SDKAppID, userID, userSig }){
   
    const options = {
        SDKAppID: SDKAppID ||20001600 // 您的即时通信 IM 应用的 SDKAppID
    };
    // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
    tim = TIM.create(options); // SDK 实例通常用 tim 表示
    const dev = process.env.NODE_ENV !== 'production'

    // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
    tim.setLogLevel(dev ? 0 : 1); // 普通级别，日志量较多，接入时建议使用
    // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

    // 注册腾讯云即时通信 IM 上传插件
    tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
    
    // 注册腾讯云即时通信 IM 本地审核插件
    tim.registerPlugin({ 'tim-profanity-filter-plugin': TIMProfanityFilterPlugin });

    tim.on(TIM.EVENT.SDK_READY, events.onSdkReady);
    tim.on(TIM.EVENT.SDK_NOT_READY, events.onSdkNotReady);
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, events.onMessageReceived);
    tim.on(TIM.EVENT.MESSAGE_MODIFIED, events.onMessageModified);
    tim.on(TIM.EVENT.MESSAGE_REVOKED, events.onMessageRevoked);
    tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, events.onMessageReadByPeer);
    tim.on(TIM.EVENT.MESSAGE_READ_RECEIPT_RECEIVED, events.onMessageReadReceiptReceived);
    tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, events.onConversationListUpdated);
    tim.on(TIM.EVENT.GROUP_LIST_UPDATED, events.onGroupListUpdated);
    tim.on(TIM.EVENT.GROUP_ATTRIBUTES_UPDATED, events.onGroupAttributesUpdated);
    tim.on(TIM.EVENT.TOPIC_CREATED, events.onTopicCreated);
    tim.on(TIM.EVENT.TOPIC_DELETED, events.onTopicDeleted);
    tim.on(TIM.EVENT.TOPIC_UPDATED, events.onTopicUpdated);
    tim.on(TIM.EVENT.PROFILE_UPDATED, events.onProfileUpdated);
    tim.on(TIM.EVENT.BLACKLIST_UPDATED, events.onBlacklistUpdated);
    tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, events.onFriendListUpdated);
    tim.on(TIM.EVENT.FRIEND_GROUP_LIST_UPDATED, events.onFriendGroupListUpdated);
    tim.on(TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED, events.onFriendApplicationListUpdated)
    tim.on(TIM.EVENT.KICKED_OUT, events.onKickedOut);
    tim.on(TIM.EVENT.NET_STATE_CHANGE, events.onNetStateChange);

    tim.login({ userID, userSig});

    Vue.prototype.$tim = tim
}
